import axios from "axios";

// export default axios.create({
//   baseURL: "http://localhost:8080",
//   headers: {
//     "Content-type": "application/json"
//   }
// });

var baseURL = "http://dev.aduello.ch";

if (process.env.REACT_APP_IMPLICIT_SERVER_ADDR && process.env.REACT_APP_IMPLICIT_SERVER_ADDR.trim().length) 
{  
  baseURL = process.env.REACT_APP_IMPLICIT_SERVER_ADDR
}
else if (process.env.REACT_APP_SERVER_ADDR && process.env.REACT_APP_SERVER_ADDR.trim().length) 
{
  baseURL = process.env.REACT_APP_SERVER_ADDR
}

export default axios.create({
  // baseURL: baseURL,
  headers: {
    "Content-type": "application/json"
  }
});