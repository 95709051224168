import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Videos from './pages/Videos'
import VoteResult from './pages/VoteResult'
import Diamonds from "./components/Diamonds";
import PrivateRoute from './routes/PrivateRoute'




//NOTE: ************************************************* order of this routes mather*************************************************
function App() {
  return (
      <Router>
        <Navbar />
        <Diamonds />
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/videos" component={Videos} />
          <PrivateRoute path="/result" component={VoteResult} />
          <PrivateRoute path="/admin" component={Admin} />
          <PrivateRoute path="/" component={Home} />
        </Switch>
        
      </Router>
  );
}

export default App;
