import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import "typeface-roboto";
import * as serviceWorker from "./serviceWorker";

import { ThemeProvider } from "@mui/styles";

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
let theme = createTheme({
  typography: {
    "fontFamily": `"Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>
  ,
  document.getElementById("root")
);
require('dotenv').config();
console.log(process.env);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
