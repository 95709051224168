import React from "react";
import { Grid, Typography, Box, Divider, Slider } from "@mui/material";
import "./Videos.css";
import UploadService from "../services/upload-files.service";
import VoteService from "../services/vote.service";
import AduelloVideo from './Video'
import Carousel from 'react-material-ui-carousel'
import { makeStyles, withStyles } from '@mui/styles';
import { useHistory, useLocation } from "react-router-dom";
import AuthService from '../services/auth.service';

const useStyles = makeStyles({
    verticalSlider: {
        height: 350,
        color: 'gray'
    },
    choosingLetters: {
        position: 'relative',
        zIndex: '109',
        top: '60px',
        left: '160px',
        fontFamily: 'auto',
    },
});


const Videos = () => {
    const classes = useStyles();
    const [file, setFile] = React.useState(null);
    const [comparingParticipants, setComparingParticipants] = React.useState({});
    const [progress, setProgress] = React.useState(0);
    const [gregorvideoSrc, setGregorVideoSrc] = React.useState("");
    const [pascalvideoSrc, setPascalVideoSrc] = React.useState("");

    const [rightVideoVolume, setRightVideoVolume] = React.useState(50);
    const leftVideoRef = React.useRef();
    const leftVideoRef2 = React.useRef();
    const rightVideoRef = React.useRef();
    const rightVideoRef2 = React.useRef();

    const location = useLocation();
    const history = useHistory();
    var contest = location.state.contest;
    let user = AuthService.getCurrentUser();
    let voterId = -1;

    const [voteAPIResult, setVoteAPIResult] = React.useState({});



    const selectFile = (event) => {
        setFile(event.target.files[0]);
    }

    const leftVolumeHandleChange = (event, newValue) => {
        setRightVideoVolume(100 - newValue);
        leftVideoRef.current.setVolume(newValue);
        rightVideoRef.current.setVolume(100 - newValue);
    };
    const rightVolumeHandleChange = (event, newValue) => {
        setRightVideoVolume(newValue);
        leftVideoRef.current.setVolume(100 - newValue);
        rightVideoRef.current.setVolume(newValue);
    };

    const findUserByVoterId = (voterId) => {
        let participantId = voteAPIResult.votes.filter(function (o) { return o.id == voterId; })[0].participantId;
        let participant = voteAPIResult.participants.filter(function (o) { return o.id == participantId; })[0];
        let userId = participant.userId;
        let user = voteAPIResult.users.filter(function (o) { return o.id == userId; })[0];
        return {user, participant};
    }

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms))


    React.useEffect(async () => {
        console.log('contest id is ' + contest.id);
        const voterIdRes = await VoteService.getOrCreateVoterId(contest.id, user.id, {});
        localStorage.setItem("userVoterId", JSON.stringify(voterIdRes.id));
        voterId = voterIdRes.id;
        console.log('voter id is ' + voterId);
        await delay(1000) /// waiting 1 second.
        const response = await VoteService.getNewVotes(contest.id, voterId);
        await delay(1000)
        setVoteAPIResult(response);
    }, [comparingParticipants]);

    const onWinnerSelected = async (AorB) => {
        let finalVote = {}
        finalVote.winnerParticipantId = (AorB == "A")? leftparticipantId : rightparticipantId;
        finalVote.loserParticipantId = (AorB == "A")? rightparticipantId : leftparticipantId;
        const voterIdRes = await VoteService.getOrCreateVoterId(contest.id, user.id, {});
        let newvoterId = voterIdRes.id;
        const response = await VoteService.setVoteResult(contest.id, newvoterId,finalVote);
        setComparingParticipants(response);
        // alert(JSON.stringify(response));
    }

    const upload = () => {
        UploadService.upload(file, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total))
        })
            .then((response) => {

            })
            .catch(() => {

            });
    }

    if (voteAPIResult == {} || voteAPIResult.votes == null || voteAPIResult.votes.length == 0) {
        return (
            <Box display='flex' >
                <Typography variant='h7' style={{ color: 'white', fontFamily: 'Helvetica' }}> Loading... </Typography>
            </Box>
        );
    }

    if (voteAPIResult.winner != -1) {
        let winner = findUserByVoterId(voteAPIResult.winner);
        let runnerup = findUserByVoterId(voteAPIResult.runnerup);
        const userVoterId = JSON.parse(localStorage.getItem('userVoterId'));
        history.push({
            pathname: '/result',
            // search: '?contest='+contest.id,
            state: { winner, runnerup, user, contest , voterId: userVoterId }
          });
        return (
            <Box display='flex'>
                
            </Box>
        );
    }

    //rank would be the same for left and right
    let rank = voteAPIResult.votes.filter(function (o) { return o.id == voteAPIResult.left; })[0].rank;
    let allvoters = voteAPIResult.participants.length;
    let totalRounds = Math.log(allvoters)/Math.log(2);
    let currentround = allvoters - parseInt(rank) + 1;
    let roundname = '';
    if (totalRounds == currentround){
        roundname = '- Final';
    }
    else if (totalRounds - 1 == currentround){
        roundname = '- Semifinals';
    }
    else if (totalRounds - 2 == currentround){
        roundname = '- Quarterfinals';
    }
    else{
        roundname = '- ' + currentround + '/' + totalRounds + ' rounds';
    }



    let leftparticipantId = voteAPIResult.votes.filter(function (o) { return o.id == voteAPIResult.left; })[0].participantId;
    let leftuserId = voteAPIResult.participants.filter(function (o) { return o.id == leftparticipantId; })[0].userId;
    let leftuser = voteAPIResult.users.filter(function (o) { return o.id == leftuserId; })[0];
    let rightparticipantId = voteAPIResult.votes.filter(function (o) { return o.id == voteAPIResult.right; })[0].participantId;
    let rightuserId = voteAPIResult.participants.filter(function (o) { return o.id == rightparticipantId; })[0].userId;
    let rightuser = voteAPIResult.users.filter(function (o) { return o.id == rightuserId; })[0];



    return (
        <Box display='flex' mx='20px' justifyContent='center' flexDirection="column">
            <Box display='flex' mx='40px' mt='20px' justifyContent='center' flexDirection="row">
                <Typography variant='h4' style={{ color: 'white', fontFamily: 'Helvetica' }}> Videos </Typography>
                <Box display="flex" mx='20px' justifyContent='flex-end' flexDirection='column' width='100%'>
                    <Divider style={{ background: 'gray', height: '4px', borderRadius: '16px' }} />
                </Box>
            </Box>
            <Box display='flex' mx='40px' mt="3px" mb='40px' justifyContent='start' flexDirection="row">
                <Typography variant='h7' style={{ color: 'white', fontFamily: 'Helvetica' }}> {contest.name} {roundname} </Typography>
            </Box>

            <Box>
                <Box style={{ display: 'flex', justifyContent: "center" }}>

                    <Slider
                        value={100 - rightVideoVolume}
                        onChange={leftVolumeHandleChange}
                        orientation="vertical"
                        defaultValue={30}
                        aria-labelledby="vertical-slider"
                    />
                    <AduelloVideo 
                        ref={leftVideoRef} 
                        isPremiumUser={true} 
                        ImageName="gregorVideo"
                        onWinnerSelected={() => onWinnerSelected('A')} 
                        participantID={leftparticipantId} 
                        Name={leftuser.username} 
                        Title='TopShot' 
                        From='CH' 
                        AorB='A' />

                    <div style={{ margin: '30px' }}></div>
                    <AduelloVideo 
                        ref={rightVideoRef} 
                        isPremiumUser={true} 
                        ImageName="pascalVideo"
                        onWinnerSelected={() => onWinnerSelected('B')} 
                        participantID={rightparticipantId} 
                        Name={rightuser.username} 
                        Title='TopShot' 
                        From='CH' 
                        AorB='B' />
                    <Slider
                        value={rightVideoVolume}
                        onChange={rightVolumeHandleChange}
                        orientation="vertical"
                        defaultValue={30}
                        aria-labelledby="vertical-slider"
                    />
                </Box>

            </Box>

            {/* <video width="320" height="240" src={pascalvideoSrc} type="video/mp4" controls>
            </video> */}
            {/* <input type="file" id="myFile" name="myFile" onChange={selectFile} />
            <input type="submit" onClick={upload} /> */}
        </Box>
    );
};

export default Videos;