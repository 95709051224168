import React from "react";
import { AppBar, Toolbar, IconButton, Button, Typography, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { spacing } from '@mui/system';
import MenuIcon from "@mui/icons-material/Menu";
import { Link, BrowserRouter as Router } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ListItemIcon from '@mui/material/ListItemIcon';
import { Settings } from "@mui/icons-material";

import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import AuthService from '../services/auth.service'
// import PersonAdd from '@material-ui/icons/PersonAdd';
// import Settings from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
import { pink, green } from '@mui/material/colors';

const Navbar = () => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    AuthService.logout();
    setAnchorEl(null);
    history.push("/login");
  }

  const handleLogin = () => {
    setAnchorEl(null);
    history.push("/login");
  }
  const handleMainMenu = () => {
    setAnchorEl(null);
    history.push("/");
  }
  

  let user = AuthService.getCurrentUser();
  let isLoggedIn = user != null

  return (
    <Router>
      <AppBar
        position="static"
        style={{ background: 'transparent', boxShadow: 'none' }}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <img src="assets/white_logo_transparent_background.png" className={classes.logo} />
          </Typography>
          <IconButton
            id="basic-button"
            fontSize="large"
            size="large"
            sx = {{color: 'gray'}}
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon  />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {
              (isLoggedIn)?
              <> 
              <MenuItem divider={true}>{'hello ' + user.username}</MenuItem>
              <MenuItem onClick={handleMainMenu}>
                <ListItemIcon>
                  <HomeIcon fontSize="small" />
                </ListItemIcon>
                Main Menu
              </MenuItem>
              {/* <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem> */}
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
              </>
              :
              <MenuItem onClick={handleLogin}>
                <ListItemIcon>
                  <LoginIcon fontSize="small" />
                </ListItemIcon>
                Login
              </MenuItem>
            }

          </Menu>
        </Toolbar>
      </AppBar>
    </Router >
  );
};



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: '10px',
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
  logo: {
    height: '100px'
  }
}));

export default Navbar;
