import { Button } from "@mui/material";
import { makeStyles, withStyles } from '@mui/styles';

import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const BootstrapButton2 = styled(Button)({
  boxShadow: 'none',
  width: '100%',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#0063cc',
  borderColor: '#0063cc',
  fontFamily: [
    '"Helvetica"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  '&:hover': {
    backgroundColor: purple[700],
  },
}));

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        width: '100%',
        textTransform: 'none',
        fontSize: 1,
        padding: '6px 12px',
        border: '5px solid',
        lineHeight: 1.5,
        backgroundColor: 'red',
        borderColor: 'red',
        // fontFamily: [
        //     'Helvetica'
        // ].join(','),
        // '&:hover': {
        //     backgroundColor: '#0069d9',
        //     borderColor: '#0062cc',
        //     boxShadow: 'none',
        // },
        // '&:active': {
        //     boxShadow: 'none',
        //     backgroundColor: '#0062cc',
        //     borderColor: '#005cbf',
        // },
        // '&:focus': {
        //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
}, { withTheme: true })(Button);

export default BootstrapButton2;