import axios from "axios";
import http from "./http-common";

// const API_URL = "http://localhost:8080/api/auth/";
// const API_URL = process.env.ServerAddress | "http://dev.aduello.ch/" + "/api/auth/";
const BasePath = "/api/contests/";

class ContestService {
  getAll() {
    return http
      .get(BasePath , {})
      .then(response => {
        return response.data;
      });
  }
}

export default new ContestService();