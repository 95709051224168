import React from "react";
import { Grid, Typography, Box, Divider } from "@mui/material";
import "./Login.css";
import LoginForm from '../components/LoginForm';

const Home = () => {
    return (
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={11} sm={10} md={9}
                className="login_container"
            >
                <Box display='flex' flexDirection='column' justifyContent='center' height='100%'>
                    <LoginForm />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Home;