import React from "react";
import { Grid, Typography, Box, TextField, Tabs, Tab, Button } from "@mui/material";
import "./Video.css";
import { makeStyles, withStyles } from '@mui/styles';
import UploadService from "../services/upload-files.service";
const { forwardRef, useRef, useImperativeHandle } = React;

//320x240, 240x180
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            margin: '10px',
            width: '25ch',
        },
    },
    playButton: {
        position: 'relative',
        zIndex: '110',
        transform: 'rotate(0deg)',
        filter: 'brightness(0%)',
        height: '30px',
        left: '380px',
        top: '15px',
        opacity: '0.9',
    },
    title: {
        position: 'relative',
        zIndex: '109',
        top: '-30px',
        left: '30px',
        fontFamily: 'Myriad Pro',
        fontSize: '16px',
        color: '#DCDCDC',
        color: 'rgb(220, 220, 220)'
    },
    title2: {
        position: 'relative',
        zIndex: '109',
        top: '410px',
        left: '30px',
        fontFamily: 'Myriad Pro',
        fontSize: '17px',
        color: 'black',
    },
    choosingLetters: {
        position: 'absolute!important',
        zIndex: '110',
        top: '460px',
        left: '300px',
        fontFamily: 'Helvetica',
        background: 'transparent',
        '& span' :{
            height: '10rem',
            lineHeight: '10rem',
            fontSize: '13rem',  
            color: 'white',             
           },
        "&:hover": {
            background: 'transparent',
            '& span' :{
                color: 'gray', 
                background: 'transparent',
               },
          },
        
        
    },
    onVideoIcons: {
        position: 'relative',
        zIndex: '108',
        top: '400px',
        left: '26px',
        fontSize: '14px',
        fontFamily: 'Helvetica',
        margin: '3px',

        "& input":{
            width: '20px',
            margin: '10px'
        }
    },
    additionalInfo: {
        position: 'relative',
        zIndex: '108',
        top: '390px',
        left: '26px',
        fontSize: '14px',
        fontFamily: 'Helvetica',
    }
}));

const Video = forwardRef((props, ref) => {
    const classes = useStyles();
    const elementRef = React.useRef();
    const ButtonRef = React.useRef();
    const [file, setFile] = React.useState(null);
    const [progress, setProgress] = React.useState(0);
    const [gregorvideoSrc, setGregorVideoSrc] = React.useState("");
    const [pascalvideoSrc, setPascalVideoSrc] = React.useState("");
    const ImageName = props.ImageName;
    const participantID = props.participantID;
    const Name = props.Name;
    const Title = props.Title;
    const From = props.From;
    const AorB = props.AorB;
    const isPremiumUser = props.isPremiumUser;

    const [currentParticipantId, setCurrentParticipantId] = React.useState(0);

    useImperativeHandle(ref, () => ({

        setVolume(volume) {
            const divElement = elementRef.current;
            divElement.volume = volume / 100;
        }

    }));

    React.useEffect(() => {
        if (currentParticipantId == participantID){
            // const divElement = elementRef.current;
            // ButtonRef.current.style.visibility = 'hidden';
            // divElement.play();
            return;
        }
        console.log("trying to get content of participant " + participantID);
        //if (localStorage.getItem(ImageName) == null) {
            UploadService.getFileByPraticipanId(participantID).then((response) => {
                var blob,
                    fileReader = new FileReader();
                // Create a blob from the response
                var uint8Array = new Uint8Array(response.data.file.data);
                var arrayBuffer = uint8Array.buffer;
                blob = new Blob([arrayBuffer], { type: response.data.type });

                // var raw = new Uint8Array(response.data.file.data.length);
                // for (let i = 0; i < response.data.file.data.length; i++) {
                //     raw[i] = response.data.file.data[i].charCodeAt(0);
                // }
                // blob = new Blob([raw], { type: response.data.type });

                // onload needed since Google Chrome doesn't support addEventListener for FileReader
                fileReader.onload = function (evt) {
                    // Read out file contents as a Data URL
                    var result = evt.target.result;
                    // Set image src to Data URL
                    setGregorVideoSrc(result);
                    const divElement = elementRef.current;
                    ButtonRef.current.style.visibility = 'hidden';
                    divElement.play();
                    setCurrentParticipantId(participantID);
                    // Store Data URL in localStorage
                    // try {
                    //     localStorage.setItem(ImageName, result);
                    // }
                    // catch (e) {
                    //     console.log("Storage failed: " + e);
                    // }
                };
                // Load blob as Data URL
                fileReader.readAsDataURL(blob);

                // setVideoSrc("data:"+ response.data.type + ";" + _arrayBufferToBase64(response.data.file));
            })
                .catch(() => {

                });

        //} else {
        //    setGregorVideoSrc(localStorage.getItem(ImageName));
        //}
    });

    const playVideo = () => {
        const divElement = elementRef.current;
        ButtonRef.current.style.visibility = 'hidden';
        divElement.play();
    }
    const videoEnded = () => {
        elementRef.current.currentTime = "0";
        ButtonRef.current.style.visibility = 'visible';
    }

    return (
        <Box className="video_container">
            <Box className='videoContent'>
                {/* <video  src={gregorvideoSrc} type="video/mp4" controls> */}
                <input type="image" src="assets/play-icon.png" ref={ButtonRef} onClick={playVideo} className={classes.playButton} />
                <video className="aduelloVideo aduelloVideoBackground" src={gregorvideoSrc} type="video/mp4" autoplay>
                </video>
                <video onEnded={videoEnded} ref={elementRef} className="aduelloVideo" src={gregorvideoSrc} type="video/mp4" autoplay>
                </video>
                {!isPremiumUser ?
                    <Box >
                        <Typography variant='subtitle2' className={classes.title}>
                            <span style={{ fontWeight: 'bold' }}>Title</span> {Title}
                        </Typography >
                        <Typography variant='subtitle2' className={classes.title}>
                            <span style={{ fontWeight: 'bold' }}>Name</span> {Name}
                        </Typography >
                        <Typography variant='subtitle2' className={classes.title}>
                            <span style={{ fontWeight: 'bold' }}>From</span> {From}
                        </Typography >
                    </Box> : < div />
                }

                <Button  className={classes.choosingLetters} onClick={props.onWinnerSelected}> 
                    <span>{AorB}</span>
                </Button >
                {isPremiumUser ?
                    <Box ml='15px' className={classes.onVideoIcons}>
                        <input type="image" src="assets/greenButtonDiamond.png" />
                        <input type="image" src="assets/goldButtonDiamond.png" />
                        <input type="image" src="assets/redButtonDiamond.png" />
                    </Box> : < div />
                }
                {isPremiumUser ?
                    <Box >
                        <Typography variant='subtitle2' className={classes.title2}>
                            <span style={{ fontWeight: 'bold' }}>Title</span> {Title}
                        </Typography >
                        <Typography variant='subtitle2' className={classes.title2}>
                            <span style={{ fontWeight: 'bold' }}>Name</span> {Name}
                        </Typography >
                        <Typography variant='subtitle2' className={classes.title2}>
                            <span style={{ fontWeight: 'bold' }}>From</span> {From}
                        </Typography >
                    </Box> : < div />
                }
                {isPremiumUser ?
                    <Box ml='15px' className={classes.onVideoIcons}>
                        <input type="image" src="assets/logo-diamond.png" />
                        <input type="image" src="assets/shareIcon.png" />
                        <input type="image" src="assets/addIcon.png" />
                        <input type="image" src="assets/logo-diamond.png" />
                        <input type="image" src="assets/hashtagIcon.png" />
                    </Box> : < div />
                }
                {!isPremiumUser ?
                    <Typography className={classes.additionalInfo}>
                        <span style={{ color: '#565656' }}>discipline rankings: 1246</span><br style={{ lineHeight: '2px' }} />
                        <span style={{ color: '#565656' }}>overal rankings: 67584</span><br />
                        <span style={{ color: '#565656' }}>duel wins: 16</span><br />
                        <span style={{ color: '#565656' }}>aduello trophies: 234</span><br />
                        <span style={{ color: '#565656' }}>jury ranking: 56</span><br />
                    </Typography > : < div />

                }
            </Box>
        </Box>
    );
});

export default Video;