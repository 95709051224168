import React from "react";
import { Grid, Typography, Box , Button } from "@mui/material";
import './VoteResult.css'
import Videos from '../components/Videos'
import Contests from './voterView/Contests'
import VoteService from '../services/vote.service'

import { makeStyles, withStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import AduelloButton from '../components/BaseComponents/AduelloButton'


const VoteResult = (props) => {

  let history = useHistory();

  const handleReset = (e) => {
    e.preventDefault();

    VoteService.resetVoteResults(props.location.state.contest.id, props.location.state.voterId, {}).then(
        () => {
            history.push({
                pathname: '/home',
                search: '?query=abc',
                state: { detail: 'some_value' }
            });
            //window.location.reload();
        },
        error => {
            //   const resMessage =
            //     (error.response &&
            //       error.response.data &&
            //       error.response.data.message) ||
            //     error.message ||
            //     error.toString();

            //   this.setState({
            //     loading: false,
            //     message: resMessage
            //   });
        }
    );
}

  return (
    <>
    <form onSubmit={handleReset}>
    <AduelloButton type='submit'  variant="contained" color="primary" disableRipple 
    style={{    
      position: "absolute",
      right: "10px",
      width: "auto",
      bottom: "10px",}} >
        vote again
    </AduelloButton>
    </form>
    <Grid container>
      {/* <Typography variant="h1" component="h2">
        Aduello
      </Typography> */}
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={11} md={11}
          className="voteresult_container"
        >
          <Box display='flex' flexDirection='row' justifyContent='center' height='100%' width='100%' className="voteResult">
            {/* <img src="assets/voteResult/basketball/basketball-952x714.jpg" className="bgImage" /> */}
            <img className={"bgImage " + props.location.state.contest.category + "-" + props.location.state.contest.discipline} />
            <Box display='flex'>
              <Typography variant='h7'  className='UserName'>{props.location.state.user.username} </Typography>
            </Box>
            <Box display='flex'>
              <Typography variant='h7'  className='WinnerName'>{props.location.state.winner.user.username} </Typography>
            </Box>
            <Box display='flex'>
              <Typography variant='h7'  className='RunnerupName'>{props.location.state.runnerup.user.username} </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
    </>
  );
};

export default VoteResult;
