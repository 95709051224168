import * as React from 'react';
import './Contests.css'
import ContestForm from './ContestForm'

import ContestService from '../../services/contest.service'

import MUIDataTable from "mui-datatables";

const columns = [
  {
    name: "category",
    label: "Category",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "discipline",
    label: "Discipline",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "challenge",
    label: "Challenge",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: false,
    }
  },
];
const options = {
  filterType: 'checkbox',
  onCellClick: (cellValue, position) => {
    console.log(position.dataIndex);
  }
};



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function BasicTable() {
  const [contests, setContests] = React.useState([]);

  React.useEffect(async () => {
    console.log("res1");
    const response = await ContestService.getAll();
    setContests(response);
  }, []);


  return (
    <>
      <MUIDataTable
        className='adminContestsContainer'
        title={""}
        data={contests}
        columns={columns}
        options={options}
      />
      {/* <TableContainer component={Paper} className='adminContestsContainer'>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Discipline&nbsp;</TableCell>
              <TableCell align="left">Challenge&nbsp;</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="left">Status&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contests.map((contest) => (
              <TableRow
                key={contest.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{contest.category}</TableCell>
                <TableCell align="left">{contest.discipline}</TableCell>
                <TableCell align="left">{contest.challenge}</TableCell>
                <TableCell component="th" scope="row">
                  {contest.name}
                </TableCell>
                <TableCell align="left">{contest.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <ContestForm> </ContestForm>
    </>
  );
}