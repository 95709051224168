import React from "react";
import { Grid, Typography, Box, TextField, Tabs, Tab, Button } from "@mui/material";
import "./Register.css";
import { makeStyles, withStyles } from '@mui/styles';

import AuthService from '../services/auth.service'

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        width: '100%',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: 'black',
        borderColor: 'white',
        fontFamily: [
            'Helvetica',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0069d9',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            margin: '10px',
            width: '25ch',
        },
    },
}));
//TODO: use react-validation for validation

const Register = () => {
    const classes = useStyles();

    const [email, setEmail] = React.useState("");
    const [username, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const onChangeUsername = (event) => {
        setUserName(event.target.value);
    };

    const handleRegister = (e) => {
        e.preventDefault();


        AuthService.register(
            username,
            email,
            password
        ).then(
            response => {
                // this.setState({
                //     message: response.data.message,
                //     successful: true
                // });
                alert('success');
            },
            error => {
                // const resMessage =
                //     (error.response &&
                //         error.response.data &&
                //         error.response.data.message) ||
                //     error.message ||
                //     error.toString();

                // this.setState({
                //     successful: false,
                //     message: resMessage
                // });
                alert('error');
            }
        );

    }

    const onChangePassword = (event) => {
        setPassword(event.target.value);
    };

    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleRegister} >

            <Box>
                <TextField id="standard-basic" label="User Name"
                    name="username"
                    value={username}
                    onChange={onChangeUsername}
                />
            </Box>
            <Box >
                <TextField id="standard-basic" label="Email"
                    name="email"
                    value={email}
                    onChange={onChangeEmail} />
            </Box>
            <Box >
                <TextField id="standard-basic" label="Password"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                />
            </Box>
            <Box >
                <BootstrapButton type="submit" variant="contained" color="primary" disableRipple className={classes.margin}>
                    Register
                </BootstrapButton>
            </Box>
        </form>
    );
};

export default Register;