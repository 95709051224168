import React from "react";
import { Grid, Typography } from "@mui/material";
import AduelloButton from '../BaseComponents/AduelloButton'
import { useHistory } from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import DateCountdown from '../BaseComponents/DateCountdown'

const ContestLoginForm = (props) => {
  const history = useHistory();
  const contest = props.contest;

  const handleClick = () => {
    console.log(contest);
    history.push({
      pathname: '/videos',
      // search: '?contest='+contest.id,
      state: { contest }
    });
  }

  return (
    <Grid container style={{background: 'white'}}>
      <Grid item xs={12} sm={12} md={12}
        className=""
        style={{ flexGrow: '0', height: '100%', color: 'black', fontSize: 'xxx-large', textAlign: 'center', marginTop: '5vh', margingBottom:'10px' }}
      >
        {contest.discipline}
      </Grid>
      <Grid item xs={12} sm={12} md={12}
        className=""
        style={{ flexGrow: '0', height: '100%', color: 'darkblue', fontSize: 'x-large' , textAlign: 'center', marginTop: '10px', margingBottom:'20px'}}
      >
        {contest.name}
      </Grid>
      <Grid item xs={12} sm={12} md={12}
        className=""
        style={{ flexGrow: '0', height: '100%', color: 'black', background: 'white' }}
      >
        <DateCountdown endDate={contest.scheduledEndDate}> </DateCountdown>
      </Grid>
      <Grid item xs={12} sm={12} md={12}
        className=""
        style={{ flexGrow: '0', height: '100%', color: 'gray', fontSize: 'x-small',  margin: '10px' , textAlign: 'center'}}
      >
        Scheduled start date: {contest.scheduledEndDate}
      </Grid>
      <Grid item xs={12} sm={12} md={12} style={{textAlign: 'center', marginTop: '20px', margingBottom:'20px'}}
        className=""
      >
        <AduelloButton style={{ flexGrow: '1', width: '50%' }} onClick={handleClick} variant="contained"  >
          Start Voting Now
        </AduelloButton>
      </Grid>

    </Grid>
  );
};

export default ContestLoginForm;