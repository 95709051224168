import React from "react";
import { makeStyles, withStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import "./Login.css";
import { Grid, Typography, Box, TextField, Tabs, Tab, Button } from "@mui/material";
import { green, purple } from '@mui/material/colors';

import AuthService from '../services/auth.service'

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        width: '100%',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: 'black',
        borderColor: 'white',
        fontFamily: [
            'Helvetica',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0069d9',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            margin: '10px',
            width: '25ch',
        },
    },
}));

const Login = () => {
    const classes = useStyles();
    let history = useHistory();

    const [username, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const onChangeUsername = (event) => {
        setUserName(event.target.value);
    };
    const onChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        AuthService.login(username, password).then(
            () => {
                history.push({
                    pathname: '/home',
                    search: '?query=abc',
                    state: { detail: 'some_value' }
                });
                //window.location.reload();
            },
            error => {
                //   const resMessage =
                //     (error.response &&
                //       error.response.data &&
                //       error.response.data.message) ||
                //     error.message ||
                //     error.toString();

                //   this.setState({
                //     loading: false,
                //     message: resMessage
                //   });
            }
        );
    }


    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleLogin}>
            <Box>
                <TextField id="standard-basic" label="User Name"
                    name="username"
                    value={username}
                    onChange={onChangeUsername}
                />
            </Box>
            <Box>

                <TextField id="standard-basic" label="Password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={onChangePassword}
                />
            </Box>
            <Box>
                <BootstrapButton type="submit" variant="contained" color="primary" disableRipple className={classes.margin}>
                    Login
                </BootstrapButton>

            </Box>

        </form>
    );
};

export default Login;