import React from "react";
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Box, TextField, Tabs, Tab, Button } from "@mui/material";
import "./LoginForm.css";
import Register from './Register';
import Login from './Login';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: '10px',
            width: '25ch',
        },
    },
    indicator: {
        backgroundColor: 'black',
    },
    tabroot: {
        color: 'black',
        opacity: '1'
    },
    form: {
        height: '100%',
        justifyContent: 'center',
    },

    heroContainer: {
        backgroundImage: `url(${"assets/logo-diamond.png"})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
}));

const LoginForm = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box mt='10px' mx='80px' display="flex" justifyContent='center'>
            <Box className='login_form_container' display='flex' flexDirection='column' >
                <Tabs
                    value={value}
                    classes={{
                        indicator: classes.indicator,
                        root: classes.tabroot
                    }}
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Login" />
                    <Tab label="Register" />
                </Tabs>
                <Box display={(value == 0) ? "flex" : "none"} className={classes.form}>
                    <Login />
                </Box>
                <Box display={(value == 1) ? "flex" : "none"} className={classes.form}>
                    <Register />
                </Box>

            </Box>
        </Box>
    );
};

export default LoginForm;