import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import './Contests.css'
import { makeStyles, withStyles } from '@mui/styles';
import { TextField, Tabs, Tab, Button } from "@mui/material";
import Carousel from 'react-material-ui-carousel'
import ContestService from '../../services/contest.service'
import ContestLoginForm from '../../components/User/ContestLoginForm'


const diciplineImage = [
    {
        category: "Sports",
        discipline: "Football",
        challenge: "16m free kick",
        image: "samples/sample-contest-1.jpg",
    },
    {
        category: "Sports",
        discipline: "Basketball",
        challenge: "3 points throw",
        image: "samples/sample-contest-2.png",
    },
    {
        category: "Music",
        discipline: "Blues",
        challenge: "Play your own composition of an instrumental X-mas Blues",
        image: "samples/sample-contest-3.jpg",
    },
    {
        category: "Music",
        discipline: "Blues",
        challenge: "2",
        image: "contests/Blues1.jpg",
    },
    {
        category: "Music",
        discipline: "Blues",
        challenge: "3",
        image: "contests/Blues2.jpg",
    },
    {
        category: "Music",
        discipline: "Blues",
        challenge: "4",
        image: "contests/Blues3.jpg",
    },
    {
        category: "Sports",
        discipline: "Basketball",
        challenge: "1",
        image: "contests/Basketball1.jpg",
    },
    {
        category: "Sports",
        discipline: "Basketball",
        challenge: "2",
        image: "contests/Basketball2.jpg",
    },
    {
        category: "Sports",
        discipline: "Basketball",
        challenge: "3",
        image: "contests/Basketball3.jpg",
    },
    {
        category: "Sports",
        discipline: "Basketball",
        challenge: "4",
        image: "contests/Basketball4.jpg",
    },
    {
        category: "Sports",
        discipline: "Football",
        challenge: "2",
        image: "contests/Football0.jpg",
    },
    {
        category: "Sports",
        discipline: "Football",
        challenge: "3",
        image: "contests/Football8.jpg",
    }
]



const Contests = () => {
    const [contests, setContests] = React.useState([]);

    React.useEffect(async () => {
        console.log("contests for user");
        const response = await ContestService.getAll();
        setContests(response);
    }, []);

    return (
        <Box display='flex' flexDirection='column' justifyContent='center' height='100%' width='100%' >
            <Box display='flex' my={5} flexDirection='column' justifyContent='center' height='40px' style={{ color: 'white', textAlign: 'center' }}>
                <span style={{ fontFamily: 'cursive', fontSize: '45px' }}></span>
            </Box>

            <Box display='flex' mb={5} mx={5} flexDirection='column' justifyContent='center' style={{ flexGrow: '1' }}>
                <Carousel
                    autoPlay={true}
                    NextIcon={<input type="image" src="assets/forwardIcon.png" id="CarouselRightArrow" />}
                    PrevIcon={<input type="image" src="assets/forwardIcon.png" id="CarouselLeftArrow" />}
                >
                    {contests.map((contest) => (
                        <Box style={{ width: '100%' }}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={8} sm={7} md={7}
                                    className=""
                                    style={{ width: '100%' }}
                                >
                                    <img 
                                      src={diciplineImage.filter( (o) => (o.category === contest.category && o.discipline === contest.discipline && o.challenge === contest.challenge))[0].image}
                                      className="" 
                                      style={{ maxHeight: '60vh', width: '50vw' }} />
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}
                                    className=""
                                    style={{ border: 'gray solid 1px', borderRadius: '10px', height: '57vh', display: 'flex' }}
                                >
                                    <Grid container direction="row" justifyContent="center" alignItems="center" style={{background: 'white', borderRadius: '10px', height: '100%'}}>
                                        <Grid item xs={12} sm={12} md={12}
                                            className=""
                                        >
                                            <ContestLoginForm contest={contest} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        // <TableRow
                        //     key={contest.name}
                        //     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        // >
                        //     <TableCell align="left">{contest.category}</TableCell>
                        //     <TableCell align="left">{contest.discipline}</TableCell>
                        //     <TableCell align="left">{contest.challenge}</TableCell>
                        //     <TableCell component="th" scope="row">
                        //         {contest.name}
                        //     </TableCell>
                        //     <TableCell align="left">{contest.status}</TableCell>
                        // </TableRow>
                    ))}

                    {/* <Box style={{ width: '100%' }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={7} sm={7} md={7}
                                className=""
                            >
                                <img src="samples/sample-contest-2.png" className="" style={{ maxHeight: '60vh' }} />
                            </Grid>
                            <Grid item xs={5} sm={5} md={5}
                                className=""
                            >
                                <Box style={{ height: '60vh' }} >
                                    B
                                </Box>
                            </Grid>
                        </Grid>
                    </Box> */}

                </Carousel>
            </Box>
        </Box>
        //     <Grid container>
        //         {/* <Typography variant="h1" component="h2">
        //     Aduello
        //   </Typography> */}
        //         <Grid container
        //             direction="row"
        //             justifyContent="center"
        //             alignItems="center"
        //         >
        //             <Grid item xs={12} sm={11} md={11}
        //                 className="voter_contests_container"
        //                 style={{backgroundColor:'blue'}}
        //             >
        //                 <Box display='flex' flexDirection='column' justifyContent='center' height='100%' style={{backgroundColor:'red'}}>
        //                     <Carousel
        //                         draggable={false}
        //                         plugins={[
        //                             {
        //                                 resolve: arrowsPlugin,
        //                                 options: {
        //                                     arrowLeft: <input type="image" src="assets/forwardIcon.png" id="CarouselLeftArrow" />,
        //                                     arrowLeftDisabled: <input type="image" src="assets/forwardIcon.png" id="CarouselLeftArrowDisabled" />,
        //                                     arrowRight: <input type="image" src="assets/forwardIcon.png" id="CarouselRightArrow" />,
        //                                     arrowRightDisabled: <input type="image" src="assets/forwardIcon.png" id="CarouselRightArrowDisabled" />,
        //                                     addArrowClickHandler: true,
        //                                 }
        //                             }

        //                         ]}>
        //                         <Box style={{ display: 'flex' }}>
        //                             A
        //                         </Box>
        //                         <Box style={{ display: 'flex' }}>
        //                             B
        //                         </Box>

        //                     </Carousel>
        //                 </Box>
        //             </Grid>
        //         </Grid>
        //     </Grid>
    );
};

export default Contests;