import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import './Admin.css'
import AdminContests from '../components/Admin/Contests'

const Admin = () => {
  return (
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={11} md={11}
          className="admin_container"
        >
          <Box display='flex' flexDirection='column' justifyContent='center' height='100%' width='100%'>
            <AdminContests />
          </Box>
        </Grid>
      </Grid>
  );
};

export default Admin;
