import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import './Home.css'
import Videos from '../components/Videos'
import Contests from './voterView/Contests'

const Home = () => {
  return (
    <Grid container>
      {/* <Typography variant="h1" component="h2">
        Aduello
      </Typography> */}
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={11} md={11}
          className="home_container"
        >
          <Box display='flex' flexDirection='column' justifyContent='center' height='100%' width='100%'>
            {/* <Videos /> */}
            <Contests />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
