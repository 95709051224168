import axios from "axios";
import http from "./http-common";

// const API_URL = "http://localhost:8080/api/auth/";
// const API_URL = process.env.ServerAddress | "http://dev.aduello.ch/" + "/api/auth/";
const BasePath = "/api/contests/";

class VoteService {
  getNewVotes(contestId, voterId) {
    let path = BasePath + contestId + "/voters/" + voterId + "/votes";
    ///api/contests/:contestId/voters/:voterId/votes
    return http
      .get(path, { params: { onlyShowCandidates: true } })
      .then(response => {
        return response.data;
      });
  }

  getOrCreateVoterId(contestId, userId, data) {
    let path = BasePath + contestId + "/voters/";
    //create or get voter id based on userId
    return http
      .post(path, { userId, data  })
      .then(response => {
        return response.data;
      });
  }

  setVoteResult(contestId, voterId, data) {
    //data loserParticipantId, winnerParticipantId
    let path = BasePath + contestId + "/voters/" + voterId + "/votes";
    ///api/contests/:contestId/voters/:voterId/votes
    return http
      .post(path, {
        ...data
      })
      .then(response => {
        return response.data;
      });
  }

  resetVoteResults(contestId, voterId, data) {
    //data loserParticipantId, winnerParticipantId
    let path = BasePath + contestId + "/voters/" + voterId + "/reset";
    ///api/contests/:contestId/voters/:voterId/votes
    return http
      .post(path, {
        ...data
      })
      .then(response => {
        return response.data;
      });
  }

}

export default new VoteService();