import http from "./http-common";

class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return http.post("/api/contents", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles(id) {
    return http.get("/api/contents/" + id);
  }

  getFileByPraticipanId(id){
    return http.get("/api/contents/participants/" + id);
  }
}

export default new UploadFilesService();