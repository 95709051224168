// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import AuthService from '../services/auth.service.js'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {

  // Add your own authentication on the below line.
  const isLoggedIn = AuthService.getCurrentUser() != null
  const isAdmin = isLoggedIn && AuthService.getCurrentUser().roles.indexOf('ROLE_ADMIN') > -1

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn == true ?
          (
            (isAdmin == true && (props.location.pathname =='/' ||  props.location.pathname =='/home') )?
              (<Redirect to={{ pathname: '/admin', state: { from: props.location } }} />) : 
              (<Component {...props} />)
          ) 
          :
          (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
      }
    />
  )
}

export default PrivateRoute