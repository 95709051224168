import React from "react";
import { Grid, Typography } from "@mui/material";
import './Diamonds.css'

const Diamonds = () => {
  return (
    <div className="diamond_container">
        <img src="assets/logo-diamond.png" className="diamond diamond-01"/>
        <img src="assets/logo-diamond.png" className="diamond diamond-02"/>
        <img src="assets/logo-diamond.png" className="diamond diamond-03"/>
        <img src="assets/logo-diamond.png" className="diamond diamond-04"/>
        <img src="assets/logo-diamond.png" className="diamond diamond-05"/>
        <img src="assets/logo-diamond.png" className="diamond diamond-06"/>
        <img src="assets/logo-diamond.png" className="diamond diamond-07"/>
        <img src="assets/logo-diamond.png" className="diamond diamond-08"/>
        <img src="assets/logo-diamond.png" className="diamond diamond-09"/>
    </div>
  );
};

export default Diamonds;